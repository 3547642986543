﻿window.locationSearch = {
    elInputs: null,
    elInputClears: null,
    elInputSearchs: null,
    elResults: null,

    init: function () {
        locationSearch.elInputs = document.querySelectorAll('.location-search input');
        locationSearch.elInputClears = document.querySelectorAll('.location-search .clear');
        locationSearch.elInputLoading = document.querySelectorAll('.location-search .loading');
        locationSearch.elInputSearchs = document.querySelectorAll('.location-search .search-icon');
        locationSearch.elResults = document.querySelectorAll('.location-search .results');

        for (let elI = 0; elI < locationSearch.elInputs.length; elI++) {
            (function () {
                const index = elI;

                // Show and hide results on focus
                const input = locationSearch.elInputs[elI];

                input.addEventListener('focus', function () {
                    locationSearch.results.show(index);
                });

                input.addEventListener('blur', function () {
                    setTimeout(function () {
                        if (document.activeElement !== input) {
                            locationSearch.results.hide(index);
                        }
                    }, 300);
                });
                locationSearch.elResults[elI].addEventListener('click', function () {
                    locationSearch.elInputs[elI].focus();
                });

                // key detection
                input.addEventListener('keyup', (e) => {
                    if (e.keyCode === 40) { //arrow down
                        locationSearch.results.selectNext(index);
                    } else if (e.keyCode === 38) {  //arrow up
                        locationSearch.results.selectPrev(index);
                    } else if (e.keyCode === 13) {  //enter
                        locationSearch.results.selectItem(index);
                    } else {
                        locationSearch.search(index);
                    }
                });

                // Remove loader on input blur
                input.addEventListener('blur', (e) => {
                    locationSearch.elInputLoading[index].style.display = 'none';
                });

                // clear click detection
                locationSearch.elInputClears[elI].addEventListener('click', function () {
                    locationSearch.clear(index);
                });
            }());
        }
    },

    clear: function (index) {
        locationSearch.elInputs[index].value = '';
        locationSearch.elInputs[index].focus();
        locationSearch.search(index);
    },

    results: {
        hide: function (index) {
            locationSearch.elResults[index].style.display = 'none';
        },

        list: function (title, items, save, index) {
            const ul = [];

            if (items.length > 0) {
                // display a title if present
                if (title.length > 0) {
                    const liTitle = document.createElement('li');
                    liTitle.classList.add('search-list-title');
                    const divTitle = document.createElement('div');

                    divTitle.innerText = title;
                    liTitle.appendChild(divTitle);
                    ul.push(liTitle);
                }

                // display items
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];

                    const a = document.createElement('a');
                    a.href = sanitize(item.url);

                    const img = document.createElement('img');
                    img.src = "https://assets.infoplaza.io/site/weersvoorspelling.nl/flags/" + sanitize(item.countryCode.toLowerCase()) + ".png";
                    img.width = 16;
                    img.height = 11;
                    a.appendChild(img);

                    const span = document.createElement('span');
                    span.innerText = sanitize(item.displayName);
                    a.appendChild(span);

                    // bind mouseover event for hover
                    if (!Site.isMobile()) {
                        a.addEventListener('mouseenter', function (e) {
                            locationSearch.elResults[index].querySelector('ul li a.selected').classList.remove('selected');
                            e.srcElement.className = 'selected';
                        });
                    }

                    const li = document.createElement('li');
                    li.classList.add('search-result');
                    li.appendChild(a);

                    ul.push(li);
                }
            }

            return ul;
        },

        selectItem: function (index) {
            const el = locationSearch.elResults[index].querySelector('ul li a.selected');
            el.click();
        },

        selectNext: function (index) {
            const ul = locationSearch.elResults[index].querySelectorAll('ul li a');
            const lis = locationSearch.elResults[index].querySelector('ul li a.selected');

            let selectIndex = -1;
            for (let i = 0; i < ul.length; i++) {
                if (ul[i] === lis) {
                    selectIndex = i + 1;
                }
            }

            if (selectIndex >= ul.length) selectIndex = 0;
            lis.classList.remove('selected');
            ul[selectIndex].className = 'selected';
        },

        selectPrev: function (index) {
            const ul = locationSearch.elResults[index].querySelectorAll('ul li a');
            const lis = locationSearch.elResults[index].querySelector('ul li a.selected');

            let selectIndex = -1;
            for (let i = 0; i < ul.length; i++) {
                if (ul[i] === lis) {
                    selectIndex = i - 1;
                }
            }

            if (selectIndex < 0) {
                selectIndex = ul.length - 1;
            }

            lis.classList.remove('selected');
            ul[selectIndex].className = 'selected';
        },

        show: function (index) {
            if (locationSearch.elResults[index].querySelector('ul li') !== null) {
                locationSearch.elResults[index].style.display = 'block';
            }
        },

        update: function (data, query, index) {
            let items = [];
            let found = false;

            // check if data is found
            if (data) {
                let resultCount = 0;
                resultCount += data.places.length;
                resultCount += data.countries.length;
                found = resultCount > 0;
            }

            if (!found) {
                if (query !== null && query.length > 0) {
                    let li = document.createElement('li');
                    li.classList.add('search-list-title');

                    let div = document.createElement('div');
                    div.classList.add("text-center");
                    div.innerText = "Geen resultaten gevonden.";

                    li.appendChild(div);
                    items = items.concat(li);
                }
            } else {
                if (showLocations) {
                    items = items.concat(locationSearch.results.list('Locaties:', data.places.slice(0, 10), true, index));
                }

                if (showSki) {
                    items = items.concat(locationSearch.results.list('Berg/skilocaties:', data.skiPlaces.slice(0, 10), false, index));
                }

                if (showCountry) {
                    items = items.concat(locationSearch.results.list('Landen:', data.countries.slice(0, 10), false, index));
                }
            }

            // remove items from previous search
            while (locationSearch.elResults[index].querySelector('ul').firstChild) {
                locationSearch.elResults[index].querySelector('ul').removeChild(locationSearch.elResults[index].querySelector('ul').firstChild);
            }

            // Add items from this search and show results
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let item = items[i];
                    locationSearch.elResults[index].querySelector('ul').appendChild(item);
                }

                locationSearch.results.show(index);
            }

            if (items.length === 0 && (query === null || query.length === 0)) {
                locationSearch.results.hide(index);
            }

            // select first link
            if (locationSearch.elResults[index].querySelector('ul li') !== null) {
                if (locationSearch.elResults[index].querySelector('ul li a')) {
                    locationSearch.elResults[index].querySelector('ul li a').className = 'selected';
                }
            }

            locationSearch.elInputLoading[index].style.display = 'none';
        }
    },

    search: function (index) {
        const query = locationSearch.elInputs[index].value;

        if (query === 'undefined') {
            query = '';
        }

        if (query.length > 0) {
            SiteLocation.search(query, locationSearch.results.update, index);

            // toggle search and clear icons
            locationSearch.elInputLoading[index].style.display = 'flex';
            locationSearch.elInputSearchs[index].style.display = 'none';
            locationSearch.elInputClears[index].style.display = 'block';
        } else {
            locationSearch.results.update(null, query, index);
            // toggle search and clear icons
            locationSearch.elInputSearchs[index].style.display = 'block';
            locationSearch.elInputClears[index].style.display = 'none';
        }
    },

    throttle: function (func, timeFrame) {
        let waiting = false;
        return function () {
            if (!waiting) {
                func.apply(this, arguments);
                waiting = true;

                setTimeout(function () {
                    waiting = false;
                }, timeFrame);
            }
        }
    },
};

locationSearch.init();
